// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "@kollegorna/cocoon-vanilla-js";
import "trix";
import "@rails/actiontext";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

import { Loading, Modal, Choice, Tab, numberFormat } from "../utils";
import {
  initSpotlight,
  initChart,
  initCocoon,
  initLayoutSearch,
  showLoading,
  hideLoading,
  loopRequest,
} from "../utils/functions";
import find from "lodash/find";

Rails.start();
ActiveStorage.start();

const application = Application.start();
const context = require.context("controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

document.addEventListener("turbo:before-visit", function (evt) {
  if (evt.detail.url && evt.detail.url.match(/memos/)) {
    showLoading();
  }
});

document.addEventListener("turbo:load", function () {
  Choice.init();
  Tab.init();
  hideLoading();
  initSpotlight();
  initChart();
  initCocoon();
  initLayoutSearch();
  window.Rails = Rails;
  window.Loading = Loading;
  window.Modal = Modal;
  window.loopRequest = loopRequest;
  window.numberFormat = numberFormat;
  window.find = find;
  if (window.loopUrl) {
    Loading.show({
      time: 0,
      text: "Please be patient, this process can take up to 10 minutes. Don't exit this screen.",
    });

    loopRequest(loopUrl, 10, () => {
      Loading.hide();
      window.loopUrl = null;
      window.location.reload();
    });
  }
});

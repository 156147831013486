import Flatpickr from "stimulus-flatpickr";

export default class extends Flatpickr {
  initialize() {}

  connect() {
    this.config = {
      ...this.config,
      enableTime: false,
      time_24hr: true,
      dateFormat: "Y-m-d",
      altInput: true,
      altFormat: "d M Y",
      onChange: (selectedDates, dateStr, instance) => {
        const input = instance.element;
        if (input && input.id === "fishing_season_start_date") {
          autoFillName({
            kind: "start",
            value: instance.currentYear,
          });
        }
        if (input && input.id === "fishing_season_end_date") {
          autoFillName({
            kind: "end",
            value: instance.currentYear,
          });
        }
      },
    };

    super.connect();
  }

  disconnect() {
    super.disconnect();
  }
}

function autoFillName(params = {}) {
  const dom = document.querySelector("#fishing_season_name");
  if (dom) {
    if (params.kind === "start") {
      dom.setAttribute("data-start", params.value);
    }
    if (params.kind === "end") {
      dom.setAttribute("data-end", params.value);
    }
    const str = generatNameString(dom.dataset);
    dom.value = str;
  }
}

function generatNameString(params = {}) {
  let result = "";
  if (!params.start) {
    return "";
  }
  if (params.start) {
    result += `FS ${params.start}`;
  }
  if (params.end && params.start != params.end) {
    result += `/${params.end}`;
  }
  return result;
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select", "btn", "iconWrapper", "iconTrue", "iconFalse"];
  static values = { checked: Boolean };

  initialize() {}

  connect() {
  }

  disconnect() {}

  toggle() {
    this.checkedValue = !this.checkedValue;
  }

  checkedValueChanged() {
    if(this.checkedValue){
      this.setChecked();
      this.showCheckedClass();
    } else {
      this.setUnChecked();
      this.showUnCheckedClass();
    }
  }

  setChecked(){
    this.selectTarget.value = 1;
    this.selectTarget.dispatchEvent(new Event("change"));
  }

  setUnChecked(){
    this.selectTarget.value = 0;
    this.selectTarget.dispatchEvent(new Event("change"));
  }

  showCheckedClass(){
    this.btnTarget.classList.remove("bg-blueGray-200");
    this.btnTarget.classList.add("bg-primary");

    this.iconWrapperTarget.classList.remove("translate-x-0");
    this.iconWrapperTarget.classList.add("translate-x-5");

    this.iconTrueTarget.classList.remove("opacity-0", "ease-out", "duration-100");
    this.iconTrueTarget.classList.add("opacity-100", "ease-in", "duration-200");

    this.iconFalseTarget.classList.remove("opacity-100", "ease-in", "duration-200");
    this.iconFalseTarget.classList.add("opacity-0", "ease-out", "duration-100");
  }

  showUnCheckedClass() {
    this.btnTarget.classList.remove("bg-primary");
    this.btnTarget.classList.add("bg-blueGray-200");

    this.iconWrapperTarget.classList.remove("translate-x-5");
    this.iconWrapperTarget.classList.add("translate-x-0");

    this.iconTrueTarget.classList.remove("opacity-100", "ease-in", "duration-200");
    this.iconTrueTarget.classList.add("opacity-0", "ease-out", "duration-100");

    this.iconFalseTarget.classList.remove("opacity-0", "ease-out", "duration-100");
    this.iconFalseTarget.classList.add("opacity-100", "ease-in", "duration-200");
  }


}

import { Controller } from "stimulus";
import * as Popper from "@popperjs/core";

/**
 * Tooltip
 * @example
  <button|div|span
    data-controller="tooltip"
    data-tooltip-content-value="Your tooltip content text"
    data-tooltip-placement-value="top|bottom ..."
  >
    Text
  </button|div|span>

  <button|div|span
    data-controller="tooltip"
    data-action="click->tooltip#toggleText"
    data-tooltip-content-value="Your tooltip content text"
    data-tooltip-click-content-value="click content"
  >
    Text
  </button|div|span>
 *
 */
export default class extends Controller {
  static values = { content: String, placement: String, clickContent: String };

  initialize() {}

  connect() {
    this.init();
  }

  disconnect() {}

  init() {
    this.createTooltip();
    this.createPopperInstance();
    this.addListener();
  }

  createTooltip() {
    const content = this.contentValue || "";
    const tooltip = document.createElement("div");
    const arrow = document.createElement("div");

    tooltip.id = `Tooltip-${Date.now()}`;
    tooltip.classList.add("tooltip");
    tooltip.setAttribute("role", "tooltip");
    arrow.classList.add("tooltip-arrow");
    arrow.setAttribute("data-popper-arrow", "");

    tooltip.innerHTML = `<div class="tooltip-content">${content}</div>`;
    tooltip.appendChild(arrow);
    document.body.appendChild(tooltip);

    this.tooltip = tooltip;
  }

  createPopperInstance() {
    const popper = Popper.createPopper(this.element, this.tooltip);
    this.popperInstance = popper;
  }

  addListener() {
    // const showEvents = ["mouseover", "focus"];
    // const hideEvents = ["mouseout", "blur"];
    const showEvents = ["mouseover"];
    const hideEvents = ["mouseout"];
    const show = () => {
      return this.show();
    };
    const hide = () => {
      return this.hide();
    };

    showEvents.forEach((event) => {
      this.element.addEventListener(event, show);
    });

    hideEvents.forEach((event) => {
      this.element.addEventListener(event, hide);
    });
  }

  show() {
    const placement = this.placementValue || "bottom";
    // Make the tooltip visible
    this.tooltip.classList.add("show");

    // Enable the event listeners
    this.popperInstance.setOptions({
      placement: placement,
      modifiers: [
        { name: "eventListeners", enabled: true },
        {
          name: "offset",
          options: {
            offset: [0, 5],
          },
        },
      ],
    });

    // Update its position
    this.popperInstance.update();
  }

  hide() {
    // Hide the tooltip
    this.tooltip.classList.remove("show");

    // Disable the event listeners
    this.popperInstance.setOptions({
      modifiers: [{ name: "eventListeners", enabled: false }],
    });

    const content = this.contentValue || "";
    const contentWrapper = this.tooltip.querySelector(".tooltip-content");
    contentWrapper.innerHTML = content;
  }

  toggleText() {
    // console.log("==== tool ==>", this);
    const clickContent = this.clickContentValue || "";
    const contentWrapper = this.tooltip.querySelector(".tooltip-content");
    if (contentWrapper) {
      contentWrapper.innerHTML = clickContent;
    }
  }
}

export function getArrayByKey(data, key) {
  var result = [];
  if (!(data instanceof Array)) {
    return result;
  }
  for (let i = 0; i < data.length; i++) {
    result.push(data[i][key]);
  }
  return result;
}

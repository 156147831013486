import { Controller } from "stimulus";
import { Grid, simpleHttpRequest } from "ag-grid-community";

import { numeral, numberFormat } from "../utils";
import { toParamString, toParamObj, merge } from "../utils/object";

export default class extends Controller {
  static targets = ["table", "btnPrev", "btnNext", "btnFirst", "btnLast"];
  static values = {
    totalPage: Number,
    url: String,
    column: String,
    filter: String,
    unresize: Boolean,
  };

  initialize() {
    // console.log("hello ctrl init ...", this);
    this.page = 1;
    this.params = {};
  }

  connect() {
    // console.log("hello Ctrl connnected !!!", this.grid);
    this.init();
  }

  disconnect() {
    // console.log("about Ctrl stoped ...", this.grid);
    if (this.grid) {
      this.grid.destroy();
    }
  }

  init() {
    const maxPage = Number(this.totalPageValue || 1);
    if (maxPage <= 1) {
      this.hasBtnFirstTarget ? this.btnFirstTarget.style.display = "none" : null;
      this.hasBtnPrevTarget ? this.btnPrevTarget.style.display = "none" : null;
      this.hasBtnNextTarget ? this.btnNextTarget.style.display = "none" : null;
      this.hasBtnLastTarget ? this.btnLastTarget.style.display = "none" : null;
    }
    if (this.hasTableTarget) {
      const columnDefs = this._generateColumn();
      const gridOptions = {
        defaultColDef: {
          resizable: true,
          flex: 1,
          minWidth: 150,
        },
        columnDefs: columnDefs,
        domLayout: "autoHeight",
        pagination: true,
        paginationPageSize: 25,
        suppressPaginationPanel: true,
        enableCellTextSelection: true,
        ensureDomOrder: true,
        onFirstDataRendered: (params) => {
          // this.resizeColumn();
        },
        onPaginationChanged: (params) => {
          if (this.page == 1) {
            this.hasBtnFirstTarget ? this.btnFirstTarget.setAttribute("disabled", true) : null;
            this.hasBtnPrevTarget ? this.btnPrevTarget.setAttribute("disabled", true) : null;
          } else {
            this.hasBtnFirstTarget ? this.btnFirstTarget.removeAttribute("disabled") : null;
            this.hasBtnPrevTarget ? this.btnPrevTarget.removeAttribute("disabled") : null;
          }

          if (this.page == maxPage) {
            this.hasBtnLastTarget ? this.btnLastTarget.setAttribute("disabled", true) : null;
            this.hasBtnNextTarget ? this.btnNextTarget.setAttribute("disabled", true) : null;
          } else {
            this.hasBtnLastTarget ? this.btnLastTarget.removeAttribute("disabled") : null;
            this.hasBtnNextTarget ? this.btnNextTarget.removeAttribute("disabled") : null;
          }
        },
      };
      const grid = new Grid(this.tableTarget, gridOptions);

      this.grid = grid;
      this.gridOptions = gridOptions;
      this._fetchData();
    }
  }

  prevPage() {
    this.page = this.page - 1 == 0 ? 1 : this.page - 1;
    this._fetchData();
  }

  nextPage() {
    const maxPage = Number(this.totalPageValue || 1);
    this.page = Math.min(this.page + 1, maxPage);
    this._fetchData();
  }

  firstPage() {
    this.page = 1;
    this._fetchData();
  }

  lastPage() {
    const maxPage = Number(this.totalPageValue || 1);
    this.page = maxPage;
    this._fetchData();
  }

  search() {
    const params = {};
    if (this.hasFilterValue) {
      const ids = this.filterValue.split(",");
      for (let index = 0; index < ids.length; index++) {
        const id = ids[index];
        const domFilter = document.querySelector(`#${id}`);
        if (domFilter && domFilter.value) {
          params[domFilter.name] = domFilter.value;
        }
      }
    }
    this.page = 1;
    this.params = params;
    this._fetchData();
  }

  // toggleColumnState(evt) {
  //   const stateList = this.gridOptions.columnApi.getColumnState();
  //   const colId = evt.target.value;
  //   if (colId) {
  //     const isShow = evt.target.checked;
  //     for (let index = 0; index < stateList.length; index++) {
  //       const stateCol = stateList[index];
  //       if (colId == stateCol.colId) {
  //         stateCol.hide = !isShow;
  //         stateCol.flex = false;
  //       }
  //     }
  //     this.gridOptions.columnApi.applyColumnState({ state: stateList });
  //   }
  // }

  resizeColumn() {
    if (this.hasUnresizeValue && this.unresizeValue) {
      return;
    }
    let allColumnIds = [];
    this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridOptions.columnApi.autoSizeColumns(allColumnIds, false);
  }

  _fetchData() {
    let url = this.urlValue;
    if (url) {
      let params = this.params;
      if (url.indexOf("?") >= 0) {
        const urlArr = url.split("?");
        const urlParams = toParamObj(urlArr[1]);
        url = urlArr[0];
        params = merge({}, params, urlParams);
      }
      params["page"] = this.page;
      simpleHttpRequest({
        url: `${url}?${toParamString(params)}`,
      }).then((data) => {
        if (this.gridOptions && this.gridOptions.api) {
          this.grid.gridOptions.api.setRowData(data);
          // this.resizeColumn();
        }
      });
    }
  }

  _generateColumn() {
    const result = [];
    const columnKey = this.hasColumnValue ? this.columnValue : "gridColumn";
    const columns = window[`${columnKey}`];
    if (columns) {
      for (let index = 0; index < columns.length; index++) {
        const col = columns[index];
        if (col.valueFormatter) {
          switch (col.valueFormatter) {
            case "number":
              col.valueFormatter = (params) => {
                return numberFormat(numeral(params.value).value());
              };
              break;

            case "currency":
              col.valueFormatter = (params) => {
                return numeral(params.value).format();
              };
              break;

            default:
              break;
          }
        }
        result.push(col);
      }
    }
    return result;
  }
}

import { Controller } from "stimulus";

/**
 * Collapse
 * @example
  // 默认关闭的
  <div data-controller="collapse" data-collapse-collapsed-value="true">
    <div|button class="collapsed" data-collapse-target="btn" data-action="click->collapse#toggle">
      ... your html code ...
      <div class="collapse-icon flex items-center justify-center w-5 h-5">
        <i class="fc fc-arrow-down-s-line"></i>
      </div>
    </div|button>
    <div class="" data-collapse-target="content">
      ... your html content ...
    </div>
  </div>

  // 默认展开的
  <div data-controller="collapse" data-collapse-collapsed-value="false">
    <div|button class="" data-collapse-target="btn" data-action="click->collapse#toggle">
      ... your html code ...
      <div class="collapse-icon flex items-center justify-center w-5 h-5">
        <i class="fc fc-arrow-down-s-line"></i>
      </div>
    </div|button>
    <div class="" data-collapse-target="content">
      ... your html content ...
    </div>
  </div>
 */
export default class extends Controller {
  static targets = ["content", "btn"];

  static values = {
    collapsed: Boolean,
  };

  initialize() {
    // console.log("hello ctrl init ...");
  }

  connect() {
    // console.log("hello Ctrl connnected !!!");
    this.init();
  }

  disconnect() {
    // console.log("about Ctrl stoped ...");
  }

  init() {
    this.element.classList.add("collapse");
    this.btnTarget.classList.add("collapse-header");
  }

  show() {
    if (this.hasContentTarget) {
      this.contentTarget.classList.remove("hidden");
    }
    if (this.hasBtnTarget) {
      this.element.classList.remove("collapsed");
    }
  }

  hidden() {
    if (this.hasContentTarget) {
      this.contentTarget.classList.add("hidden");
    }
    if (this.hasBtnTarget) {
      this.element.classList.add("collapsed");
    }
  }

  toggle() {
    this.collapsedValue = !this.collapsedValue;
  }

  collapsedValueChanged() {
    if (this.collapsedValue) {
      this.hidden();
    } else {
      this.show();
    }
  }
}

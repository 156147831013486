import { merge } from "./object";

/**
 * Loading
 * 显示 loading 效果
 *
 * @example
 *
 * 显示
 * Loading.show();                // 3秒中之后自动关闭
 * Loading.show({
 *   text: "Your text here ...",  // 提示文字
 *   time: 5000,                  // 毫秒，5秒中之后自动关闭
 *   showIcon: true|false         // 是否显示 icon
 * })
 * Loading.show({ time: 0 })      // 不会自动关闭，需手动关闭
 *
 * 关闭
 * Loading.hide();
 */
class Loading {
  static defaultConfig = {
    text: "Loading ...",
    time: 3000,
    showIcon: true,
  };

  static show(setting) {
    return new Loading(setting);
  }

  static hide() {
    const loading = window.LoadingCurrent;
    if (loading) {
      document.body.removeChild(loading);
      window.LoadingCurrent = null;
    }
  }

  constructor(setting = {}) {
    if (window.LoadingCurrent) {
      return;
    }
    this._setting = merge({}, Loading.defaultConfig, setting);

    this.init();
  }

  init() {
    const id = `Loading-${Date.now()}`;
    const dom = document.createElement("div");
    dom.id = id;
    dom.className = "loading";

    const domInner = document.createElement("div");
    domInner.className = "loading-inner";

    let domIcon = null;
    if (this._setting.showIcon) {
      domIcon = document.createElement("div");
      domIcon.className = "loading-icon";
      domIcon.innerHTML = `
        <div class="spinner w-16 h-16"></div>
      `;
    }

    const domText = document.createElement("div");
    domText.className = "loading-text";
    domText.innerText = this._setting.text;

    dom.appendChild(domInner);
    if (domIcon) {
      domInner.appendChild(domIcon);
    }
    domInner.appendChild(domText);
    document.body.appendChild(dom);

    // 储存到window
    window.LoadingCurrent = dom;

    if (this._setting.time) {
      setTimeout(() => {
        Loading.hide();
      }, this._setting.time);
    }
  }
}

export default Loading;

import { Controller } from "stimulus";

export default class extends Controller {
  static values = { autohide: Boolean };

  barTimeout = null;

  initialize() {
    this.start();
  }

  connect() {}

  disconnect() {
    this.stop();
  }

  hide() {
    this.removeMsg();
  }

  start() {
    this.stop();
    if (this.hasAutohideValue && this.autohideValue) {
      this.barTimeout = setTimeout(() => {
        this.removeMsg();
      }, 5000);
    }
  }

  stop() {
    if (this.barTimeout) {
      clearTimeout(this.barTimeout);
      this.barTimeout = null;
    }
  }

  removeMsg() {
    this.element.remove();
  }
}

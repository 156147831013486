import { toParamString } from "./object";

function myFetch(url, opts = {}) {
  let urlCopy = url;
  const method = opts.method || "GET";
  if (method === "GET" && opts.data) {
    const data = opts.data || {};
    delete opts.data;
    urlCopy += `?${toParamString(data)}`;
  }
  return fetch(urlCopy, opts);
}

export default myFetch;
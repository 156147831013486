import { Controller } from "stimulus";

import Gauge from "svg-gauge";

/**
 * Gauge (Circle Progress)
 <div class="flex items-center justify-center"
  data-controller="gauge"
  data-gauge-total="100"
  data-gauge-remain="35">
  <div data-gauge-target="circle" class="gauge-container one ..."></div>
</div>
 */
export default class extends Controller {
  static targets = ["circle"];

  initialize() {
    // console.log("hello ctrl init ...");
    this.initCircle();
  }

  connect() {
    // console.log("hello Ctrl connnected !!!");
    this.start();
  }

  disconnect() {
    // console.log("about Ctrl stoped ...");
  }

  initCircle() {
    if (this.hasCircleTarget) {
      const total = Number(this.data.get("total")) || 100;
      this.gauge = Gauge(this.circleTarget, {
        dialStartAngle: -90,
        dialEndAngle: -90.001,
        max: total,
        value: 0,
        showValue: false,
        label: function (value) {
          return Math.round(value) + "/" + this.max;
        },
      });
    }
  }

  start() {
    const total = Number(this.data.get("total")) || 100;
    const remain = Number(this.data.get("remain")) || 0;
    const used = total - remain;
    this.gauge.setValueAnimated(used, 1);
  }
}

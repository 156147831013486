import { Controller } from "stimulus";

/**
 * Copy
 *
 * @example
  <button|div|span
    data-controller="copy"
    data-copy-content-value="Some wrods here"
    data-action="click->copy#copy"
  >
    some text or icon
  </button|div|span>
 */
export default class extends Controller {
  static values = { content: String };

  initialize() {}

  connect() {}

  disconnect() {}

  copy() {
    const content = this.contentValue;
    if (content) {
      const input = document.createElement("input");
      input.type = "text";
      input.value = content;
      input.style.opacity = "0";
      input.style.position = "fixed";
      input.style.left = "-9999px";
      input.style.zIndex = -9999;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      input.remove();
    }
  }
}

function isObject(obj) {
  return Object.prototype.toString.call(obj) === "[object Object]";
}
function isArray(arr) {
  return Array.isArray(arr);
}
export function merge(target, ...arg) {
  return arg.reduce((acc, cur) => {
    return Object.keys(cur).reduce((subAcc, key) => {
      const srcVal = cur[key];
      if (isObject(srcVal)) {
        subAcc[key] = merge(subAcc[key] ? subAcc[key] : {}, srcVal);
      } else if (isArray(srcVal)) {
        // series: []，下层数组直接赋值
        subAcc[key] = srcVal.map((item, idx) => {
          if (isObject(item)) {
            const curAccVal = subAcc[key] ? subAcc[key] : [];
            return merge(curAccVal[idx] ? curAccVal[idx] : {}, item);
          } else {
            return item;
          }
        });
      } else {
        subAcc[key] = srcVal;
      }
      return subAcc;
    }, acc);
  }, target);
}

/**
 * Object 转变为 url 参数字符串
 * @param {Object} obj {a: "aaa", b: "bbb"}
 * @returns String "a=aaa&b=bbb"
 */
export function toParamString(obj = {}) {
  const arr = Object.keys(obj).map((key) => {
    return `${key}=${obj[key]}`;
  });
  return arr.join("&");
}

/**
 * String 转化为 Object
 * @param {String} str "a=aaa&b=bbb"
 * @returns Object {a: "aaa", b: "bbb"}
 */
export function toParamObj(str = "") {
  const result = {};

  const arr = str.split("&");
  for (let index = 0; index < arr.length; index++) {
    const item = arr[index];
    const itemArr = item.split('=');
    result[`${itemArr[0]}`] = itemArr[1];
  }

  return result;
}

import Chart from "chart.js/auto";

import zip from "lodash/zip";
import { getArrayByKey } from "./array";
import { merge } from "./object";
const bgPrimary = "#635BFF";
const bgDanger = "#ef4444";
const bgSuccess = "#22c55e";
const bgWarning = "#f97316";
const bgGray = "#cbd5e1";
const borderGray = "#e2e8f0";
const borderDark = "#0f172a";
const colorBgSets = [bgPrimary, bgGray, bgDanger, bgSuccess, bgWarning];
const colorBorderSets = [bgPrimary, bgDanger, bgSuccess, bgWarning];

/**
 * Chart
 *
 * @example
  // html
  <canvas id="myChart"></canvas>

  // js
  var data = [
    {label: "Your Label", value: 1000}, ...
  ];

 */
class MyChart {
  static defaultConfig = {
    plugins: {
      legend: false,
    },
    elements: {
      bar: {
        borderRadius: 1,
        backgroundColor: bgPrimary,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: true,
        },
      },
      y: {
        grid: {
          color: (context) => {
            if (context.tick.value == 0) {
              return borderDark;
            } else {
              return borderGray;
            }
          },
        },
      },
    },
  };

  static bar(domId, data, customOpts) {
    if (!data.length) {
      return;
    }
    const configBar = merge({}, this.defaultConfig, {
      type: "bar",
      ...customOpts,
    });
    return new MyChart(domId, data, configBar);
  }

  static line(domId, data, customOpts) {
    if (!data.length) {
      return;
    }
    const configLine = merge({}, this.defaultConfig, {
      type: "line",
      ...customOpts,
    });
    return new MyChart(domId, data, configLine);
  }

  constructor(domId, data, setting) {
    this._domId = domId;
    this._data = data;
    this._setting = setting;

    this._dom = document.getElementById(domId);

    this.generateChart();
  }

  generateChart() {
    if (!this._dom) {
      return;
    }
    let chart = null;
    const labels = getArrayByKey(this._data, "label");
    const values = getArrayByKey(this._data, "value");
    const datasets = this._setting.multy ? zip(...values) : [values];

    switch (this._setting.type) {
      case "line":
        chart = this.generateLine({
          labels: labels,
          datasets: datasets.map((dataset, index) => {
            const i = index % colorBorderSets.length;

            const item = {
              data: dataset,
              tension: 0.4,
              borderWidth: 2,
              radius: 0,
              borderColor: colorBorderSets[i],
              backgroundColor: colorBorderSets[i],
            };

            if(this._setting.legendList){
              item.label = this._setting.legendList[index];
            }

            return item;
          }),
        });
        break;

      case "bar":
        chart = this.generateBar({
          labels: labels,
          datasets: datasets.map((dataset, index) => {
            const i = index % colorBgSets.length;
            return {
              // barThickness: 40,
              // maxBarThickness: 80,
              data: dataset,
              backgroundColor: colorBgSets[i],
            };
          }),
        });
        break;

      default:
        throw "no chart type";
        break;
    }

    if (chart) {
      window[`Chart-${this._setting.type}-${this._domId}`] = chart;
    }
  }

  generateBar(data) {
    return new Chart(this._dom, {
      type: "bar",
      data: data,
      options: this._setting,
    });
  }

  generateLine(data) {
    return new Chart(this._dom, {
      type: "line",
      data: data,
      options: this._setting,
    });
  }
}

export default MyChart;

/**
 * Modal
 *
 * @example
  <button onclick="Modal.open('SomeModalId')"> Open Modal </button>
  <button onclick="Modal.close('SomeModalId')"> Close Modal </button>
  <button onclick="Modal.toggle('SomeModalId')"> Toggle Modal </button>
 */
class Modal {
  static open(id) {
    window.ModalContainer = window.ModalContainer || {};
    return new Modal(id);
  }

  static close(id) {
    const modal = window.ModalContainer[`id-${id}`];
    if (modal) {
      modal.close();
    }
  }

  static toggle(id) {
    const modal =
      window.ModalContainer && window.ModalContainer[`id-${id}`]
        ? window.ModalContainer[`id-${id}`]
        : null;
    if (modal && modal.opened) {
      Modal.close(id);
    } else {
      Modal.open(id);
    }
  }

  constructor(id) {
    this._id = id;
    this._dom = document.getElementById(id);
    this.opened = false;

    this._init();
    this._addEventListener();
  }

  _init() {
    if (this._dom) {
      this._dom.classList.add("show");
      setTimeout(() => {
        this._dom.classList.add("fade");
        this.opened = true;
        window.ModalContainer[`id-${this._id}`] = this;
      }, 100);
    }
  }

  _addEventListener() {
    if (this._dom) {
      const backdrop = this._dom.querySelector(".modal-backdrop");
      const close = this._dom.querySelector(".modal-close");
      if (backdrop) {
        backdrop.addEventListener("click", () => {
          this.close();
        });
      }
      if (close) {
        close.addEventListener("click", () => {
          this.close();
        });
      }
    }
  }

  // _changeZindex(zIndex) {
  //   const dom = document.querySelector(".layout-side");
  //   if(dom) {
  //     dom.style.zIndex = zIndex;
  //   }
  // }

  close() {
    if (this._dom) {
      this._dom.classList.remove("fade");
      setTimeout(() => {
        this._dom.classList.remove("show");
        this.opened = false;
      }, 100);
    }
  }
}

export default Modal;

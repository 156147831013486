import debounce from "lodash/debounce";
import values from "lodash/values";
import flatten from "lodash/flatten";

import Chart from "./chart";
import Choice from "./choices";
import Modal from "./modal";
import numeral from "./numeral";
import fetch from "./fetch";

export const noop = () => {};
const searchUrl = "/search/global";

// 监听 cmd + k 组合按钮，打开 Spotlight
export function initSpotlight() {
  const input = document.querySelector("#SpotlightInput");
  const domResult = document.querySelector("#SpotlightResult");
  const domSplit = document.querySelector("#SpotlightResultSplit");
  const init = () => {
    addEvent();
  };
  const renderResult = (data) => {
    domSplit.style.display = "block";
    const tmpList = flatten(values(data));
    if (tmpList.length > 0) {
      addResult(data);
    } else {
      emptyResult();
    }
  };
  const addResult = (data) => {
    let html = "";

    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const list = data[key];
        list.forEach((item) => {
          html += `<li>
            <a class="link" href="${item.url}">
              <span class="flex w-4 justify-center mr-3">
                ${searchResultIcons[key]}
              </span>
              <span class="capitalize font-medium text-default">
                ${key}
              </span>
              <svg class="w-3 h-3 mx-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
              </svg>
              <span>
                ${item.label}
              </span>
            </a>
          </li>`;
        });
      }
    }

    domResult.innerHTML = html;
  };
  const emptyResult = () => {
    domResult.innerHTML = `<li>
      <div class="py-3 px-5 border-l-2 border-transparent">No results.</div>
    </li>`;
  };
  const hideResult = () => {
    domResult.innerHTML = "";
  };
  const startRequest = (keyWords) => {
    if (!keyWords) {
      domSplit.style.display = "none";
      hideResult();
      return;
    }

    fetch(searchUrl, {
      data: {
        keyword: keyWords,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        renderResult(result);
      })
      .catch((err) => {
        // console.error("== error ==>", err);
      });
  };
  const addEvent = () => {
    document.addEventListener("keydown", function (evt) {
      const name = evt.key;
      // const code = evt.code;
      if (name === "Meta") {
        // Do nothing.
        return;
      }
      if (evt.metaKey && name === "k") {
        Modal.toggle("ModalSpotlight");
        input.focus();
      }
    });
    if (input) {
      input.addEventListener(
        "input",
        debounce((evt) => {
          const value = evt.target.value;
          startRequest(value);
        }, 800)
      );
    }
  };
  init();
}

// 监听 订单新建页面的 Cocoon 的回调事件
export function initCocoon() {
  const orderItems = document.getElementById("OrderItems");
  if (orderItems) {
    orderItems.addEventListener("cocoon:before-insert", function () {});
    orderItems.addEventListener("cocoon:after-insert", function () {
      Choice.init();
    });
    orderItems.addEventListener("cocoon:before-remove", function () {});
    orderItems.addEventListener("cocoon:after-remove", function () {});
  }
  const billItems = document.getElementById("BillItems");
  if (billItems) {
    billItems.addEventListener("cocoon:after-insert", function () {
      Choice.init();
    });
  }
}

// init layout search
export function initLayoutSearch() {
  const mainLayout = document.querySelector(".layout-main");
  const input = document.querySelector(".layout-search-input");
  const searchDropdown = document.querySelector(".layout-search-dropdown");
  const searchLoading = document.querySelector(".search-loading");
  const searchEmpty = document.querySelector(".search-result-empty");
  const searchList = document.querySelector(".search-result-list");

  const init = () => {
    addEvent();
  };

  const open = () => {
    searchDropdown.classList.add("show");
    mainLayout.style.overflowY = "hidden";
  };
  const close = () => {
    mainLayout.style.overflowY = "auto";
    setTimeout(() => {
      searchDropdown.classList.remove("show");
    }, 600);
  };

  const startRequest = (keyWords) => {
    if (!keyWords) {
      close();
      hideDom(searchLoading);
      hideDom(searchEmpty);
      hideDom(searchList);
      return;
    }

    open();
    hideDom(searchEmpty);
    hideDom(searchList);
    showDom(searchLoading);

    fetch(searchUrl, {
      data: {
        keyword: keyWords,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        hideDom(searchLoading);
        renderResult(result);
      })
      .catch((err) => {
        // console.error("== error ==>", err);
      });
  };

  const renderResult = (data) => {
    const tmpList = flatten(values(data));
    if (tmpList.length > 0) {
      showDom(searchList);
      hideDom(searchEmpty);
      addResult(searchList, data);
    } else {
      showDom(searchEmpty);
      hideDom(searchList);
      emptyResult(searchList);
    }
  };

  const showDom = (dom) => {
    dom ? (dom.style.display = "block") : null;
  };

  const hideDom = (dom) => {
    dom ? (dom.style.display = "none") : null;
  };

  const emptyResult = (dom) => {
    while (dom.firstChild) {
      dom.removeChild(dom.firstChild);
    }
  };

  const addResult = (dom, data) => {
    let html = "";

    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const list = data[key];
        list.forEach((item) => {
          html += `<li>
            <a class="search-item" href="${item.url}">
              ${searchResultIcons[key]}
              <span class="capitalize font-medium text-default">
                ${key}
              </span>
              <svg class="search-item-arrow" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
              </svg>
              <span>
                ${item.label}
              </span>
            </a>
          </li>`;
        });
      }
    }

    dom.innerHTML = html;
  };

  const addEvent = () => {
    if (input && searchDropdown) {
      input.addEventListener("focus", () => {
        if (input.value) {
          open();
        }
      });
      input.addEventListener("blur", () => {
        close();
      });
      input.addEventListener(
        "input",
        debounce((evt) => {
          const value = evt.target.value;
          startRequest(value);
        }, 800)
      );
    }
  };

  init();
}

// init chart
export function initChart() {
  if (window.catchRemainingData && window.unit_name && window.seasonList) {
    Chart.line("ChartCatchRemaining", catchRemainingData, {
      multy: true,
      legendList: seasonList,
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          ticks: {
            callback: (val, index) => {
              return `${numeral(val).format("0,0")} ${unit_name}`;
            },
          },
        },
      },
      plugins: {
        legend: true,
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              const season = tooltipItem.dataset.label;
              const d = numeral(tooltipItem.raw).format("0,0");
              return `${season}: ${d} ${unit_name}`;
            },
          },
        },
      },
    });
  }
  if (window.taccData) {
    Chart.bar("ChartTacc", taccData, {
      scales: {
        y: {
          ticks: {
            callback: (val, index) => {
              return `${numeral(val).format("0,0")}kg`;
            },
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              return `${numeral(tooltipItem.raw).format("0,0")}kg`;
            },
          },
        },
      },
    });
  }

  if (window.orderBillDataUrl) {
    fetch(orderBillDataUrl)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // 此数据类型，multy = false
        // var orderBillData = [
        //   { label: "2021-01", value: 1000 },
        //   { label: "2021-02", value: 800 },
        //   { label: "2021-03", value: 850 },
        //   { label: "2021-04", value: 866 },
        // ];
        // 此数据类型，multy = true
        // var orderBillData = [
        //   { label: "2021-01", value: [1000, -200] },
        //   { label: "2021-02", value: [1100, -300] },
        //   { label: "2021-03", value: [1050, -260] },
        //   { label: "2021-04", value: [1206, -410] },
        // ];
        Chart.bar("ChartOrderBill", data, {
          multy: true,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              ticks: {
                callback: (val, index) => {
                  return numeral(val).format("$0,0");
                },
              },
            },
          },
          plugins: {
            tooltip: {
              position: "nearest",
              callbacks: {
                label: (tooltipItem) => {
                  const labelArr = ["Revenue: ", "Expenses: "];
                  const label = labelArr[tooltipItem.datasetIndex] || "";
                  return label + numeral(tooltipItem.raw).format();
                },
                footer: (tooltipItems) => {
                  let sum = 0;
                  tooltipItems.forEach((tooltipItem) => {
                    sum += tooltipItem.parsed.y;
                  });
                  return "Net: " + numeral(sum).format();
                },
              },
            },
          },
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  }
}

// 显示页面的 Loading ， memos 页面切换当前 memo 使用
export function showLoading() {
  const loading = document.getElementById("MemoLoading");
  if (loading) {
    loading.style.display = "";
  }
}
// 关闭页面的 Loading
export function hideLoading() {
  const loading = document.getElementById("MemoLoading");
  if (loading) {
    loading.style.display = "none";
  }
}

/**
 * 循环请求 api
 * @param {String} url 需要请求的地址
 * @param {Number} time 请求间隔的时间， 秒
 * @param {Func} callback 回调函数，请求成功之后的操作
 *
 * 应用于 fishing season show 页面的批量库存修改
 *
 * @example
 * loopRequest("/path/to/api", 5)
 * loopRequest("/path/to/api", 5, () => {  // your code })
 */
export function loopRequest(url, time, callback) {
  let timeBar = null;
  const seconds = time || 10;

  const start = () => {
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result) {
          switch (result.status) {
            case "success":
              stop();
              callback && callback();
              break;

            case "processing":
              timeBar = setTimeout(() => {
                start();
              }, seconds * 1000);
              break;

            case "stop":
              stop();
              break;

            default:
              break;
          }
        }
      })
      .catch((err) => {
        stop();
      });
  };

  const stop = () => {
    if (timeBar) {
      clearTimeout(timeBar);
      timeBar = null;
    }
  };

  start();
}

import { Controller } from "stimulus";

/**
 * Progress
 * @example
  <div class="progress" data-controller="progress" data-progress-percent-value="0.555">
    <div class="progress-inner" data-progress-target="inner" style="width: 0"></div>
  </div>
 *
 */
export default class extends Controller {
  static targets = ["inner"];
  static values = { percent: Number };

  initialize() {}

  connect() {
    this.start();
  }

  disconnect() {}

  start(){
    const percent = Number(this.percentValue) || 0;
    const width = `${(percent*100.0).toFixed(2)}%`;
    setTimeout(() => {
      this.innerTarget.style.width = width;
    }, 10);
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggleable"];

  initialize() {}

  connect() {}

  disconnect() {}

  toggle() {
    const list = this.toggleableTargets;
    for (let index = 0; index < list.length; index++) {
      const dom = list[index];
      if (dom.classList.contains("hidden")) {
        dom.classList.remove("hidden");
      } else {
        dom.classList.add("hidden");
      }
    }
  }

  show(){
    const list = this.toggleableTargets;
    for (let index = 0; index < list.length; index++) {
      const dom = list[index];
      dom.classList.remove("hidden");
    }
  }

  hide(){
    setTimeout(() => {
      const list = this.toggleableTargets;
      for (let index = 0; index < list.length; index++) {
        const dom = list[index];
        dom.classList.add("hidden");
      }
    }, 800);
  }
}

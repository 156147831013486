import { Controller } from "stimulus";
import SlimSelect from "slim-select";
/**
 * Slim Select
 *
 * @example
  1. single
  <select data-controller="slim-select" class="hidden">
    <option value="value 1">Value 1</option>
    <option value="value 2">Value 2</option>
    <option value="value 3">Value 3</option>
  </select>
  2. multiple
  <select data-controller="slim-select" multiple class="hidden">
    <option value="value 1">Value 1</option>
    <option value="value 2">Value 2</option>
    <option value="value 3">Value 3</option>
  </select>
  3. placeholder
  <select data-controller="slim-select" class="hidden">
    <option data-placeholder="true">Please select</option>
    <option value="value 1">Value 1</option>
    <option value="value 2">Value 2</option>
    <option value="value 3">Value 3</option>
  </select>

  4. 其他配置
  <select data-controller="slim-select" data-slim-select-options-value="{'limit': 2}" class="hidden">
    <option data-placeholder="true">Please select</option>
    <option value="value 1">Value 1</option>
    <option value="value 2">Value 2</option>
    <option value="value 3">Value 3</option>
  </select>

  其他配置详见 https://slimselectjs.com/
 */
export default class extends Controller {
  static values = {
    options: Object, // 例： {'limit': 2, 'showSearch': false}
  };

  connect() {
    this.slimselect = new SlimSelect({
      select: this.element,
      ...this.optionsValue,
    });
    this.element.classList.remove("hidden");
  }

  disconnect() {
    this.slimselect.destroy();
  }
}

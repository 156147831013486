/**
 * Tab
 * @example
  <div class="tab" role="tabs">
    <div class="tab-bars flex" role="tablist">
      <a href="#tab1" data-toggle="tab" class="tab-bars-item active">Tab 1</a>
      <a href="#tab2" data-toggle="tab" class="tab-bars-item">Tab 2</a>
      <a href="#tab3" data-toggle="tab" class="tab-bars-item">Tab 3</a>
      <a href="#tab4" data-toggle="tab" class="tab-bars-item">Tab 4</a>
    </div>
    <div class="tab-content">
      <div class="tab-pane tab1 active">
        This is tab 1
      </div>
      <div class="tab-pane tab2">
        Some thing for 2
      </div>
      <div class="tab-pane tab3">
        You can see 3 here
      </div>
      <div class="tab-pane tab4">
        It's true 4
      </div>
    </div>
  </div>
 */
class Tab {
  static init() {
    const tabs = document.querySelectorAll("[data-toggle='tab']");
    tabs.forEach((item) => {
      item.addEventListener("click", (evt) => {
        evt.preventDefault();
        toggleBarActive(evt);
        toggleContentActive(evt);
        triggerCustomEvent(evt);
      });
    });
  }
}

function toggleBarActive(evt) {
  let parent;
  const list = evt.path;
  for (let index = 0; index < list.length; index++) {
    const dom = list[index];
    if (dom.getAttribute("role") == "tablist") {
      parent = dom;
      break;
    }
  }
  if (parent) {
    const currentDom = parent.querySelector(".active");
    if (currentDom) {
      currentDom.classList.remove("active");
    }
  }
  evt.target.classList.add("active");
}

function toggleContentActive(evt) {
  const href = evt.target.hash || "";
  const targetClass = href.replace("#", "");
  let parent;
  const list = evt.path;
  for (let index = 0; index < list.length; index++) {
    const dom = list[index];
    if (dom.getAttribute("role") == "tabs") {
      parent = dom;
      break;
    }
  }
  if (parent) {
    // const target = document.getElementById(targetId);
    const target = parent.querySelector(`.${targetClass}`);
    const currentDom = parent.querySelector(".tab-content .active");
    if (currentDom) {
      currentDom.classList.remove("active");
    }
    if (target) {
      target.classList.add("active");
    }
  }
}

function triggerCustomEvent(evt) {
  const event = new CustomEvent("hq.tab.show", {
    detail: {
      ...item.dataset
    },
  });
  evt.target.dispatchEvent(event);
}

export default Tab;

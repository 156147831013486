/**
 * Number Formater Utils
 *
 * @example
 *
 * let num1 = 10;
 * let num2 = 1023;
 * // 使用默认格式，格式化数字
 * numeral(num1).format() // => "$10.00"
 * numeral(num2).format() // => "$1,023.00"
 * // 使用自定义格式，格式化数字
 * numeral(num1).format("0,0") // => "10" 注意，这里没有 $
 * numeral(num2).format("0,0") // => "1,023" 注意，这里没有 $
 *
 */
import numeral from "numeral";
numeral.defaultFormat("$0,0.00");

export default numeral;

/**
 * 转化小数，进位
 * @param {Number|String} num 要变化的数
 * @param {Number} digit 小数位数, 默认 2
 *
 * @example
 * numberCeil(123.456789, 2) // => 123.46
 * numberCeil(123.123456, 2) // => 123.13
 * numberCeil(123.120006, 2) // => 123.13
 *
 * numberCeil(123.456789, 3) // => 123.457
 * numberCeil(123.123456, 3) // => 123.124
 * numberCeil(123.120006, 3) // => 123.121
 */
export function numberCeil(num, digit = 2) {
  const multiple = Math.pow(10, digit);
  return numeral(Math.ceil(numeral(num).multiply(multiple).value()))
    .divide(multiple)
    .value();
}

/**
 * 转化小数，舍位
 * @param {Number|String} num 要变化的数
 * @param {Number} digits 小数位数, 默认 2
 *
 * @example
 * numberFloor(123.456789, 2) // => 123.45
 * numberFloor(123.123456, 2) // => 123.12
 * numberFloor(123.120006, 2) // => 123.12
 *
 * numberFloor(123.456789, 3) // => 123.456
 * numberFloor(123.123456, 3) // => 123.123
 * numberFloor(123.120006, 3) // => 123.120
 */
export function numberFloor(num, digits) {
  const d = digits || 2;
  const multiple = Math.pow(10, d);
  return numeral(Math.floor(numeral(num).multiply(multiple).value()))
    .divide(multiple)
    .value();
}

/**
 * 格式化小数
 *
 * @example
 * numberFormat(2345)  // => "2,345"
 * numberFormat(2345.6)  // => "2,345.6"
 * numberFormat(2345.67)  // => "2,345.67"
 * numberFormat(2345.678)  // => "2,345.68"
 * numberFormat(2345.671)  // => "2,345.67"
 */
export function numberFormat(num) {
  const number = Number(num);
  if (number) {
    const numberStr = `${number}`;
    const numberArr = numberStr.split(".");
    if (numberArr[1] && numberArr[1].length >= 2) {
      return numeral(number).format("0,0.00");
    }
    if (numberArr[1] && numberArr[1].length == 1) {
      return numeral(number).format("0,0.0");
    }
    return numeral(number).format("0,0");
  }
  if(number == 0) {
    return "0"
  }
  return "";
}
